<template>
	<div class="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
		<div class="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-no-wrap">
			<div class="ml-4 mt-2">
				<h3 class="text-lg leading-6 font-medium text-gray-900">
					{{ title }}
				</h3>
			</div>
			<div v-if="action.name" class="ml-4 mt-2 flex-shrink-0">
				<span class="inline-flex rounded-md shadow-sm">
					<router-link
						:to="action.to"
						:data-of-id="action.name"
						class="of-track relative inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:shadow-outline"
					>
						{{ action.name }}
					</router-link>
				</span>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'TopBar',
	props: {
		title: {
			type: String,
			default: '',
			required: true
		},
		action: {
			type: Object,
			default: () => ({}),
			required: false
		}
	}
}
</script>

<style></style>
