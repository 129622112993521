<template>
	<lottie :options="defaultOptions" @anim-created="handleAnimation" />
</template>

<script>
import Lottie from '@/components/shared/lottie.vue'
import * as animationData from '@/assets/data.json'
export default {
	components: {
		lottie: Lottie
	},
	data() {
		return {
			defaultOptions: { animationData: animationData.default },
			animationSpeed: 1
		}
	},
	methods: {
		handleAnimation: function(anim) {
			this.anim = anim
		}
	}
}
</script>
